<template>
  <section id="export-data">
    <b-card title="Export Data">
      <b-form-group
        label="Selected Hubs"
      >
        <v-select
          multiple
          v-model="selectedHubs"
          label="name"
          :options="hubs"
        />
      </b-form-group>
      <b-row>
        <b-col
          xl="4"
          md="4"
        >
          <b-form-group
            label="Data"
            label-for="selected-data"
          >
            <v-select
              id="selected-data"
              v-model="selectedDataExport"
              label="label"
              :options="dataExport"
              @input="changeSelectedDataExport"
            />
          </b-form-group>
        </b-col>
        <b-col
          xl="4"
          md="4"
        >
          <b-form-group
            label="Selected Cols"
            label-for="selected-cols"
          >
            <v-select
              id="selected-cols"
              multiple
              v-model="selectedCols"
              label="label"
              :options="tableCols"
            />
          </b-form-group>
        </b-col>
        <b-col
          xl="4"
          md="4"
        >
          <b-form-group
            label="Order by"
            label-for="selected-order-by"
          >
            <v-select
              id="selected-order-by"
              v-model="selectedColOrderBy"
              label="label"
              :options="tableCols"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <hr>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="exportData"
      >
        Export
      </b-button>
    </b-card>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BButton,
  BCard,
} from "bootstrap-vue"
import vSelect from "vue-select"
import Ripple from "vue-ripple-directive"
import axios from "axios"

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BCard,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      hubs: [],
      selectedHubs: [],
      exportParams: {},
      dataExport: ["Orders", "Invoices", "InvoicesCorrections"],
      tableCols: [],
      selectedDataExport: "",
      selectedCols: [],
      selectedColOrderBy: {label: "", key: ""},
    }
  },
  methods: {
    getMyHubs() {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/hubs/all/my/", {
          headers: {
            "Authorization": `JWT ${this.$store.state.jwt}`,
            "Content-Type": "application/json"
          },
        })
        .then(response => {
          const myHubs = response.data.results.map( item => { return item.name})
          this.hubs = myHubs
          this.selectedHubs = myHubs
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$router.push({ name: "login" })
          }
        })
    },
    changeSelectedDataExport() {
      let reqUrl
      if (this.selectedDataExport == "Orders") {
        reqUrl = "/orders-cols/"
      }
      else if (this.selectedDataExport == "Invoices") {
        reqUrl = "/invoices-cols/"
      }
      else if (this.selectedDataExport == "InvoicesCorrections") {
        reqUrl = "/invoices-cors-cols/"
      }

      if (reqUrl) {
        axios
          .get(process.env.VUE_APP_ROOT_API + reqUrl, {
            headers: {
              "Authorization": `JWT ${this.$store.state.jwt}`,
              "Content-Type": "application/json"
            },
          })
          .then(response => {
            this.tableCols = response.data
            this.selectedCols = response.data
          })
          .catch(error => {
            if (error.response.status == 401) {
              this.$router.push({ name: "login" })
            }
          })
      }
    },
    exportData() {
      this.exportParams["selected_hubs"] = this.selectedHubs
      this.exportParams["selected_cols"] = this.selectedCols.map(elem => elem)
      this.exportParams["ordering"] = this.selectedColOrderBy
      let reqUrl
      if (this.selectedDataExport == "Orders") {
        reqUrl = "/all-orders/"
      }
      else if (this.selectedDataExport == "Invoices") {
        reqUrl = "/all-invoices/"
      }
      else if (this.selectedDataExport == "InvoicesCorrections") {
        reqUrl = "/all-invoices-cors/"
      }

      if (reqUrl) {
        axios
          .get(process.env.VUE_APP_ROOT_API + reqUrl, {
            headers: {
              "Authorization": `JWT ${this.$store.state.jwt}`,
              "Content-Type": "application/json"
            },
            params: this.exportParams,
          })
          .then(response => {
            let exportedData = response.data
            let csvContent = "data:text/csv;charset=utf-8," + Object.keys(exportedData[0]).map(e => e).join(";")
            csvContent += "\n"
            csvContent += exportedData.map(e => Object.values(e).join(";")).join("\n")
            var encodedUri = encodeURI(csvContent)
            var link = document.createElement("a")
            link.setAttribute("href", encodedUri)
            link.setAttribute("download", this.selectedDataExport + ".csv")
            document.body.appendChild(link)
            link.click()
          })
          .catch(error => {
            if (error.response.status == 401) {
              this.$router.push({ name: "login" })
            }
          })
      }
    }
  },
  created() {
    this.getMyHubs()
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss'
</style>
